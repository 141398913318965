<template>
  <div>
    <div v-for="option in options" :key="option.id" class="modifier-group" :id="'option-'+option.id">
      <div class="insidePadding">

        <strong class="modifier-title" :class="{'text-danger': errors.some(e => e.id === option.id)}">{{option.name}}</strong>
        <div class="modifier-description" v-if="!errors.some(e => e.id === option.id)">{{option.description}} {{option.minSelect > 0 ? (option.minSelect > 1 ? 'obligatoires' : 'obligatoire') : ''}}</div>

        <span class="modifier-error text-danger" v-if="errors.some(e => e.id === option.id)">
          <i class="material-icons">error</i>
          {{$t('errors.'+errors.find(e => e.id === option.id).error, {min: option.minSelect, max: option.maxSelect})}} ({{option.description}})
        </span>

        <div class="modifier-line-group">
          <label v-for="value in option.values" :key="value.id" class="modifier-item" :class="{'modifier-item-unavailable': !value.available}">
            <div v-if="cart.type !== 'on_site'">
              <input :class="option.maxSelect === 1 ? 'form-radio' : 'form-checkbox'"
                    type="checkbox"
                    v-model="value.checked"
                    :disabled="!value.available"
                    @change="updateCheckbox(option, value)"
                    v-if="(option.quantityEnabled === false || option.maxSelect === 1)">
              <div class="modifier-buttons" v-else-if="option.quantityEnabled">
                <div @click="changeQuantityOfItem(option, value, -1)" v-show="value.quantity >= 1" class="modifier-quantity-button" :class="{'modifier-quantity-button-active': value.quantity > 0}"><i class="material-icons">remove</i></div>
                <span v-if="value.quantity >= 1" class="counter">{{value.quantity}}</span>
                <div @click="changeQuantityOfItem(option, value, 1)" class="modifier-quantity-button" :class="{'modifier-quantity-button-active': value.quantity > 0}"><i class="material-icons">add</i></div>
              </div>
            </div>

            <span class="modifier-name" :style="!value.available ? 'color: #c2c2c2; text-decoration: line-through;' :''" @click="changeQuantityOfItem(option, value, 1)">{{value.name}}</span>
            <small v-if="value.available && value.price && value.price.value > 0">{{value.price.price}}</small>
            <small v-if="!value.available" style="color: #c2c2c2;">Indisponible</small>
          </label>
        </div>
      </div>

      <div class="productLine"></div>

    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    product: {
      required: true
    }
  },
  data(){
    return {
      selectedOptions: [],
      options: [],
      errors: []
    }
  },
  methods: {
    changeQuantityOfItem(option, value, quantity){
        let totalValueSelectedInOption = option.values.reduce((total, currentValue) => total + currentValue.quantity, 0);
        if(!option.quantityEnabled
            || option.maxSelect === 1
            || totalValueSelectedInOption + quantity > option.maxSelect
            || totalValueSelectedInOption + quantity < 0
            || !value.available
      ){
        return;
      }
      value.quantity += quantity;
      if(value.quantity === 0){
        value.checked = false;
      }
      else{
        value.checked = true;
      }
    },

    validateFields(){
      this.errors = [];
      if(this.options.length === 0){
        return true;
      }

      this.options.forEach((option) => {
        if(option.minSelect > 0){
          let selected = this.selectedOptions.find(o => o.id === option.id);
          if(!selected){
            this.errors.push({id: option.id, error: 'option_not_selected'})
          }
          else{
            const totalQuantity = selected.values.reduce((total, currentValue) => total + currentValue.quantity, 0);
            if(totalQuantity < option.minSelect){
              this.errors.push({id: option.id, error: 'not_enough_selected'})
            }
            if(totalQuantity > option.maxSelect){
              this.errors.push({id: option.id, error: 'too_much_selected'})
            }
          }
        }
      })

      if(this.errors.length === 0){
        return true;
      }
      return false;
    },

    getOptionErrorIds(){
      return this.errors.map(e => e.id);
    },

    updateCheckbox(option, value){
      if(value.checked && option.maxSelect === 1){
        option.values.forEach(v => {
          v.checked = value.id === v.id;
          v.quantity = value.id === v.id ? 1 : 0;
        });
      }
      if(!value.checked){
        value.quantity = 0;
      }
    },

    parseOptionValues(options){
      this.selectedOptions = options.map(option => {
        return {
          id: option.id,
          values: option.values.filter(v => v.checked || v.quantity > 0).map(v => {
            return {
              id: v.id,
              quantity: v.quantity === 0 ? 1 : v.quantity,
              checked: v.checked || v.quantity > 0,
              price: v.price.value,
            }
          })
        }
      }).filter(o => o.values.length > 0);
      this.$emit('input', this.selectedOptions);
    }
  },
  watch: {
    options: {
      handler: function (val, oldVal) {
        this.parseOptionValues(val);
        if(this.errors.length > 0){
          this.validateFields();
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['cart'])
  },
  mounted() {
    if(this.product.options.length > 0){
      this.parseOptionValues(this.product.options);
      const options = JSON.parse(JSON.stringify(this.product.options));
      options.forEach(opt => opt.values.sort((a,b) => b.available - a.available))
      //options.forEach(option => option.values.forEach((value) => {value.quantity = 0 ; value.checked = false}));
      this.options = options;
    }
  }
}
</script>
