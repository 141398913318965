<template>
  <ui-modal ref="panel" :dismissible="dismissible" v-bind="$attrs" :transition="transition" :size="size" @close="back" class="panel-base" removeHeader>

    <slot name="nav" v-if="!hideNav">
      <ui-toolbar class="nav" nav-icon="west" :remove-nav-icon="type === 'panel'" @nav-icon-click="back" :raised="false">
        {{title}}

        <div slot="actions" v-if="type === 'panel'">
          <ui-icon-button
              color="black"
              class="card-button"
              size="large"
              icon="close"
              type="secondary"
              @click="back"
              v-if="!hideCloseButton"
          ></ui-icon-button>
        </div>

      </ui-toolbar>
    </slot>

    <slot></slot>

    <slot name="footer" v-if="!hideValidateButton">
      <div class="modal-fixed-bottom-button">
        <ui-button :color="validateButtonColor" size="large" :loading="isLoading" :disabled="isLoading" @click="validate">{{validateButton}}</ui-button>
      </div>
    </slot>

  </ui-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  inheritAttrs: false,
  props: {
    type: {
      default: 'panel',
      type: String
    },
    dismissible: {
      default: true,
      type: Boolean
    },
    title: {
      default: 'Title',
      type: String
    },
    validateButton: {
      default: 'validate',
      type: String
    },
    validateButtonColor: {
      default: 'primary',
      type: String
    },
    hideValidateButton: {
      default: false,
      type: Boolean
    },
    eventName: {
      required: true,
      type: String
    },
    closeEventName: {
      required: false,
      type: String
    },
    hideCloseButton: {
      default: false,
      type: Boolean
    },
    transition: {
      default: 'slide-top',
      type: String
    },
    hideNav: {
      default: false,
      type: Boolean
    },
    isLoading: {
      default: false,
      type: Boolean
    },
    size: {
      default: 'fullscreen',
      type: String
    }
  },
  data(){
    return {
      uniqueId: null,
      isOpen: false,
      payload: false
    }
  },
  methods: {
    ...mapActions(['openModal', 'closeModal']),

    open(){
      this.payload = false;
      this.uniqueId = Math.round(Math.random() * 1000);
      if(this.$route.hash !== '#' + this.eventName){
        this.$router.push('#' + this.eventName);
      }
      this.isOpen = true;
      this.openModal(this.eventName);
      if(this.$refs.panel){
        this.$refs.panel.open();
      }
    },


    close(){
      this.isOpen = false;
      this.closeModal(this.eventName);
      if(this.$refs.panel){
        this.$refs.panel.close();
      }
      this.$emit('close', this.payload);
    },

    back(){
      if(this.isOpen === false){
        return false;
      }
      if(this.modal[this.modal.length-2]){
        this.$router.push('#' + this.modal[this.modal.length-2]);
      }
      else{
        this.$router.push('');
      }
    },


    listen(){
      this.$root.$on(this.eventName, (payload) => {
        this.open();
        this.$emit('open', payload);
      })
      if(this.closeEventName){

        this.$root.$on(this.closeEventName, (payload) => {
          this.payload = payload;
          this.back();
        })
      }
    },


    validate(){
      this.$emit('validated');
    }


  },
  computed: {
    ...mapGetters(['cart', 'modal'])
  },
  mounted() {
    this.listen();
  },
  watch: {
    $route (to, from){
      if(from.hash === '#'+this.eventName){
        if(this.modal[this.modal.length-1] === this.eventName){
          this.close({to:to, from:from});
        }
      }
    },
    modal(val) {
      if(val.indexOf(this.eventName) !== -1){
        if(!this.isOpen){
          this.open();
        }
      }
      else{
          if(this.isOpen){
            this.$router.replace('')
          }
      }
    }
  }
}
</script>
