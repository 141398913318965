<template>
  <vue-final-modal name="cart-time"
                   v-bind="$attrs"
                   v-slot="{close}"
                   :classes="['vfm-container vfm-content']"
                   v-on="$listeners">

  </vue-final-modal>
</template>

<script>
import ModalHeader from '@/components/ModalHeader.vue';
export default {
  components: {ModalHeader}
}
</script>