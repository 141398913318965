<template>
  <div class="announcements">
    <div v-for="an in announcements">
      <ui-alert class="m-0" v-if="an.type === 'info'" :dismissible="false" type="info"><span v-html="an.body"></span></ui-alert>
      <ui-alert class="m-0" v-if="an.type === 'error'" :dismissible="false" type="error"><span v-html="an.body"></span></ui-alert>
      <ui-alert class="m-0" v-if="an.type === 'success'" :dismissible="false" type="success"><span v-html="an.body"></span></ui-alert>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  props: {
    position: {
      required: true,
      type: String
    }
  },
  computed: {
    ...mapGetters(['merchant', 'cart']),
    announcements(){
      if(!this.merchant) return  [];

      if(this.cart){
        return this.merchant.announcements
            .filter(an => an.position === this.position)
            .filter(an => an.mode.length === 0 || an.mode.includes(this.cart.type))
      }
      else{
        return this.merchant.announcements.filter(an => an.position === this.position);
      }
    }
  },
  mounted() {
  }
}
</script>
