<template>
  <vue-final-modal name="edit-product" v-bind="$attrs" v-slot="{close}"
    :classes="['vfm-container vfm-product', product && product.imageLarge && product.imageLarge.path ? 'vfm-product__with-image' : '']"  v-on="$listeners" content-class="modalBoxShadow">
    <div class="product_container scroll" v-if="product">
      <div class="product_image" v-if="product.imageLarge && product.imageLarge.path"
        :style="'background-image: url('+product.imageLarge.path+')'">
        <button class="product_image_back d-flex d-lg-none" @click="close">
          <i class="material-icons">arrow_back</i></button>
      </div>
      <div class="product_content">
        <div class="product_content_data scroll">

          <div class="insidePadding">

            <button class="product_image_back d-flex d-lg-none d-flex d-lg-none mb-1 justify-content-start px-0"
              v-if="!product.imageLarge || !product.imageLarge.path" @click="close"><i class="material-icons">arrow_back</i></button>
            <div class="data__header">
              <h4 class="font-weight-bold">{{product.name}}</h4>
              <button @click="close" class="d-none d-lg-flex"><i class="material-icons">close</i></button>
            </div>
            <span class="description">{{product.description}}</span>

          </div>

          <div class="productLine"></div>

          <product-options ref="optionSelector" :product="product" v-model="selectedOptions"/>

          <div class="product-comment insidePadding">
            <label>Instructions spécifiques</label>
            <textarea v-model="comment" placeholder="Ajoutez vos allergies ou vos commentaires (plus de sauce, pas d'oignon...)" class="form-control"></textarea>
            <small>Si vous souffrez d'allergies alimentaires ou si vous suivez un régime alimentaire spécifique, veuillez prendre contact directement avec le restaurant avant de passer votre commande.</small>
          </div>

        </div>
        <div class="product_content_footer d-none d-lg-flex">

          <div class="product-quantity" v-if="cart.type !== 'on_site'">
            <button @click="quantity--" :disabled="quantity === 0"><i class="material-icons">remove</i></button>
            <span>{{quantity}}</span>
            <button @click="quantity++"><i class="material-icons">add</i></button>
          </div>

          <button class="product_cta product_cta_blue" style="gap:0;" @click="openCartTime"
                  v-if="showAvailabilityChanger">
            <div style="font-size: 80%;">Changer date de la commande</div>
            <small style="font-size: 67%;">Dispo. uniquement {{category.availability.text}}</small>
          </button>

          <button class="product_cta product_cta_red" @click="addToCartButton" :disabled="isLoading" v-else-if="quantity === 0">
            <div v-if="!isLoading">Supprimer du panier</div>
            <loader v-if="isLoading"></loader>
          </button>

          <button class="product_cta" @click="addToCartButton" :disabled="isLoading" v-else-if="quantity > 0">
            <loader v-if="isLoading"></loader>
            <div class="addToCart" v-if="!isLoading">Modifier le panier</div>
            <div class="price" v-if="!isLoading">{{totalProductPrice}}</div>
          </button>

        </div>
      </div>
    </div>
    <div class="product_content_footer d-lg-none">

      <div class="product-quantity" v-if="cart.type !== 'on_site'">
        <button @click="quantity--" :disabled="quantity === 0"><i class="material-icons">remove</i></button>
        <span>{{quantity}}</span>
        <button @click="quantity++"><i class="material-icons">add</i></button>
      </div>

      <button class="product_cta product_cta_blue" @click="openCartTime"
              v-if="showAvailabilityChanger">
        <span>Changer de date pour modifier</span>
      </button>

      <button class="product_cta product_cta_red" @click="addToCartButton" :disabled="isLoading" v-else-if="quantity === 0">
        <span v-if="!isLoading">Supprimer du panier</span>
        <loader v-if="isLoading"></loader>
      </button>

      <button class="product_cta" @click="addToCartButton" :disabled="isLoading" v-else>
        <loader v-if="isLoading"></loader>
        <span v-if="!isLoading">Modifier le panier</span>
        <small class="price"  v-if="!isLoading">{{totalProductPrice}}</small>
      </button>


    </div>
  </vue-final-modal>
</template>

<style lang="scss">
.productLine {
  height: 3px;
  width: 100%;
  background-color: #F0F0F0;
}
.insidePadding {
  padding: 20px;
}
</style>

<script>
import {mapActions, mapGetters} from "vuex";
import {$vfm} from "vue-final-modal";
import ProductOptions from '@/components/Product/ProductOptions.vue';
import CartTime from "@/components/Modals/Cart/CartTime.vue";

export default {
  name: 'EditProduct',
  components: {ProductOptions},
  props: {
    cartProduct: {
      required: true
    }
  },
  data(){
    return {
      selectedOptions: [],
      product: false,
      isLoading: false,
      quantity: 1,
      comment: '',
      category: null
    }
  },
  methods: {
    ...mapActions(['updateCartProduct']),

    openCartTime(){
      this.$root.$emit('openOrderTime', {next:this.category.availability.availableTimestamp});
    },

    addToCartButton(){
      // Delete product
      if(this.quantity === 0){
        this.isLoading = true;
        this.updateCartProduct({
          options: [],
          comment: this.comment,
          productId: this.product.id,
          cartProductId: this.cartProduct.id,
          quantity: this.quantity
        })
            .then(() => {
              this.isLoading = false;
              $vfm.hide('edit-product');
            });
        return;
      }

      // Add to cart
      if(this.$refs.optionSelector.validateFields()){
        const optionFlat = [];
        this.selectedOptions.forEach(option => {
          option.values.forEach((value) => {
            optionFlat.push({
              optionId: option.id,
              valueId: value.id,
              quantity: value.quantity
            });
          })
        });

        this.isLoading = true;
        this.updateCartProduct({
          options: optionFlat,
          comment: this.comment,
          productId: this.product.id,
          cartProductId: this.cartProduct.id,
          quantity: this.quantity
        })
        .then(() => {
          this.isLoading = false;
          $vfm.hide('edit-product');
        });

      }
      else{
        const errorOptionIds = this.$refs.optionSelector.getOptionErrorIds();
        document.getElementById('option-'+errorOptionIds[0])
            .scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
      }
    }
  },
  computed: {
    ...mapGetters(['cart', 'merchant', 'menu']),
    getCurrentPrice(){
      let price = this.product.priceDelivery;
      if(this.cart.type === 'delivery'){
        return this.product.priceDelivery;
      }
      if(this.cart.type === 'pickup'){
        return this.product.pricePickup;
      }
      if(this.cart.type === 'onsite' || this.cart.type === 'on_site_counter'){
        return this.product.priceOnsite;
      }
    },
    showAvailabilityChanger(){
      return this.category && this.category.availability && !this.category.availability.available;
    },
    totalProductPrice(){
      if(!this.getCurrentPrice){
        return false;
      }
      const optionTotalValues = this.selectedOptions.reduce((bigsum, o) => bigsum + o.values.reduce((sum, v) => sum + v.price * v.quantity, 0), 0);
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
          (optionTotalValues + this.getCurrentPrice.value) * this.quantity / 100
      )
    }
  },
  watch: {
    menu(){
      this.category = this.menu.find(m => m.id === this.product.categoryId);
    }
  },
  mounted() {
    this.product = this.cartProduct.product;
    this.category = this.menu.find(m => m.id === this.product.categoryId);
    this.quantity = this.cartProduct.quantity;
    this.comment = this.cartProduct.comment;
  }
}
</script>
